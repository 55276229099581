import { Box, Button, CircularProgress, Typography } from '@mui/material'
import styles from './displaybooks.module.css'
import { DisplayMediaBooksProps } from '../displaybox.interface'
import React from 'react'

const DisplayBooks = ({ title, url, image, description, loading, loadingFunction }: DisplayMediaBooksProps) => {
  const [insideLoading, setInsideLoading] = React.useState(false)
  const downloadFile = () => {
    loadingFunction(true)
    setInsideLoading(true)
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        // the filename you want
        a.download = `drcaballero`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        loadingFunction(false)
        setInsideLoading(false)
      })
      .catch(() => {
        loadingFunction(false)
        setInsideLoading(false)
      })
  }
  return (
    <Box className={styles.container}>
      <Box className={styles['mini-container']}>
        <img src={image} alt={title} className={styles.image} />
      </Box>
      <Box className={styles['text-container']}>
        <Typography className={styles.title}>{title}</Typography>
        <Typography className={styles.description}>{description}</Typography>
        <Button 
          onClick={downloadFile}
          sx={{
            pointerEvents: loading ? 'none' : 'auto',
            opacity: loading ? 0.5 : 1,
            width: '160px',
          }}
        >
          {insideLoading 
            ? <CircularProgress
                color='inherit'
                size={24}
                thickness={6}
              /> 
            : 'Descargar'
          }
        </Button>
      </Box>
    </Box>
  )
}

export default DisplayBooks
