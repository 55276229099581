import { Box, Typography } from '@mui/material'
import styles from './infinitecarousel.module.css'
import { carouselItems } from './infinitecarousel.array'
import { useState } from 'react'
import Slider from 'react-slick'

const InfiniteCarousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0)

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    className: 'slick-carousel',
    slidesToShow: 3,
    centerMode: true,
    slidesToScroll: 1,
    focusOnSelect: true,
    afterChange: (current: any) => setCurrentSlide(current),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      }
    ]
  }
  return (
    <Box
      sx={{
        width: '100%'
      }}
    >
      <Slider {...settings}>
        {carouselItems.map(item => (
          <Box
            className={styles['carousel-container']}
            sx={{
              padding: {
                xs:
                  item.id === currentSlide
                    ? '10px !important'
                    : '25px !important',
                md:
                  item.id === currentSlide
                    ? '20px !important'
                    : '35px !important',
                lg:
                  item.id === currentSlide
                    ? '30px !important'
                    : '45px !important',
                xl:
                  item.id === currentSlide
                    ? '40px !important'
                    : '55px !important'
              },
              transition: 'all ease-in .05s'
            }}
            key={`carousel-${item.id}`}
          >
            <img
              src={item.image}
              alt="Placeholder"
              style={{
                width: '100%',
                height: 'auto',
                borderRadius: '50px',
                cursor: 'pointer'
              }}
            />
          </Box>
        ))}
      </Slider>
      <Box
        sx={{
          marginTop: '80px',
          textAlign: 'center'
        }}
      >
        <Typography
          className={styles.title}
          sx={{
            fontSize: {
              xs: '32px',
              lg: '40px'
            },
            lineHeight: {
              xs: '38px',
              lg: '46px'
            }
          }}
        >
          {carouselItems.find(item => item.id === currentSlide)?.title}
        </Typography>
      </Box>
      <Box
        sx={{
          marginTop: '50px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: {
            xs: '0px 60px',
            md: '0px 90px',
            lg: '0px 120px'
          }
        }}
      >
        {carouselItems
          .find(item => item.id === currentSlide)
          ?.specialtie.map((sp, index) => (
            <Box
              key={`specialtie-${index}`}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '15px 35px',
                backgroundColor: 'var(--background-blue)'
              }}
            >
              <Typography
                className={styles['specialtie-title']}
                sx={{
                  fontSize: {
                    xs: '12px',
                    md: '16px'
                  },
                  lineHeight: {
                    xs: '12px',
                    md: '16px'
                  },
                  textAlign: 'center'
                }}
              >
                {sp.description}
              </Typography>
            </Box>
          ))}
      </Box>
    </Box>
  )
}

export default InfiniteCarousel
