import { SpecialtiesProps } from './specialties.interface'
import specialtie1 from 'assets/icons/specialties/specialtie-1.svg'
import specialtie2 from 'assets/icons/specialties/specialtie-2.svg'
import specialtie3 from 'assets/icons/specialties/specialtie-3.svg'
import specialtie4 from 'assets/icons/specialties/specialtie-4.svg'
import cardImg from 'assets/images/card-placeholder.svg'

const specialtiesArray: SpecialtiesProps[] = [
  {
    title: 'Ginecologia',
    text: '',
    img: specialtie1
  },
  {
    title: 'Cirugia endoscópica',
    text: '',
    img: specialtie2
  },
  {
    title: 'Fertilidad',
    text: 'Técnicas de Reproducción Asistida',
    img: specialtie3
  },
  {
    title: 'Ultrasonido integral',
    text: 'Tiroideo, mamario, de abdomen general y pélvico transvaginal',
    img: specialtie4
  },
]

export default specialtiesArray
