import { Box, Button, useMediaQuery } from '@mui/material'
import { NavbarMenu } from './navbar.interface'
import NavbarBox from './NavbarBox'
import header from 'assets/logos/header.svg'
import pinkLogo from 'assets/logos/pink-logo.svg'
import styles from './navbar.module.css'
import { useTheme } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'

const Navbar = () => {
  const navigate = useNavigate()
  const location = window.location.pathname
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const navbarMenu: NavbarMenu[] = [
    {
      title: 'Inicio',
      link: '/'
    },
    {
      title: 'Multimedia',
      link: '/media'
    }
  ]
  const scrollToContact = () => {
    if (location === '/') {
      const contact = document.getElementById('contact')
      contact?.scrollIntoView({ behavior: 'smooth' })
    } else {
      setTimeout(() => {
        const contact = document.getElementById('contact')
        contact?.scrollIntoView({ behavior: 'smooth' })
      }
      , 1000)
      navigate('/')
    }
  }
  return (
    <Box
      className={styles.container}
      sx={{
        height: {
          xs: '75px',
          lg: '90px'
        },
        backgroundColor: 'var(--white)',
        maxWidth: {
          xs: '100%',
          lg: '1500px'
        },
        boxShadow: '0px 16.739999771118164px 21px 10px rgba(170, 170, 170, 0.06)',
      }}
    >
      <Box
        component={'figure'}
        className={styles.figure}
        sx={{
          marginLeft: {
            xs: '20px',
            sm: '30px'
          },
          marginTop: {
            xs: '7px',
            sm: '5px'
          }
        }}
        onClick={() => navigate('/')}
      >
        <img
          className={styles.image}
          src={isMobile ? pinkLogo : header}
          alt="Header Logo"
        />
      </Box>
      <Box
        className={styles['list-container']}
        sx={{
          display: 'flex'
        }}
      >
        {navbarMenu.map((item, index) => (
          <NavbarBox
            key={`navbar-${index}`}
            link={item.link}
            title={item.title}
          />
        ))}
      </Box>
      <Button
        sx={{
          marginRight: '30px',
          padding: {
            xs: '10px 20px',
            lg: '12px 24px'
          },
          display: {
            xs: 'none',
            md: 'block'
          }
        }}
        onClick={scrollToContact}
      >
        Contacto
      </Button>
    </Box>
  )
}

export default Navbar
