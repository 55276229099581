import { ContactProps } from "./contact.interface";
import whatsappIcon from 'assets/icons/contact-whatsapp.svg'
import instagramIcon from 'assets/icons/contact-instagram.svg'
import callIcon from 'assets/icons/contact-call.svg'

export const contactArray: ContactProps[] = [
    {
        title: 'Whatsapp',
        value: '+58 414-3263207',
        image: whatsappIcon
    },
    {
        title: 'Instagram',
        value: '@drcaballerosantasofia',
        image: instagramIcon
    },
    {
        title: 'Teléfono',
        value: '+58 414-3263207',
        image: callIcon
    },
]