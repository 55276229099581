import React from 'react'
import { SpecialtiesProps } from '../specialties.interface'
import SpecialtiesArrow from '../SpecialtiesArrow'
import Slider from 'react-slick'
import { Box } from '@mui/material'
import SpecialtiesBox from '../SpecialtiesBox'
import specialtiesArray from '../specialties.array'
import styles from './specialtiescarrousel.module.css'

const SpecialtiesCarrousel = () => {
  const specialties = specialtiesArray
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    className: styles['slick-carousel'],
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SpecialtiesArrow prev={true} />,
    prevArrow: <SpecialtiesArrow prev={false} />,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }
  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: 'var(--white)',
        borderRadius: '50px'
      }}
    >
      <Slider {...settings}>
        {specialties.map((specialty, index) => (
          <Box key={index}>
            <SpecialtiesBox
              title={specialty.title}
              text={specialty.text}
              img={specialty.img}
            />
          </Box>
        ))}
      </Slider>
    </Box>
  )
}

export default SpecialtiesCarrousel
