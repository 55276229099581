import { Box, Typography } from '@mui/material'
import styles from './presentation.module.css'
import HomeLayout from 'components/HomeLayout'
import blackLogo from 'assets/logos/black-logo.svg'
import presentationImage from 'assets/images/presentation-image.svg'

const Presentation = () => {
  return (
    <Box className={styles.wrapper}>
      <HomeLayout
        className={styles.container}
        style={{
          padding: {
            xs: '60px 30px',
            md: '60px'
          },
          overflow: 'hidden'
        }}
      >
        <Box
          className={styles.logo}
          sx={{
            right: {
              xs: '65px',
              md: '90px'
            }
          }}
        >
          <img src={blackLogo} alt="Logo" className={styles.image} />
        </Box>
        <Box className={styles.box}>
          <Box>
            <Typography
              className={styles.title}
              sx={{
                fontSize: {
                  xs: '16px',
                  sm: '24px',
                  md: '32px'
                },
                lineHeight: {
                  xs: '20px',
                  sm: '28px',
                  md: '38px'
                }
              }}
            >
              Doctor
            </Typography>

            <Typography
              className={styles.title}
              sx={{
                fontSize: {
                  xs: '16px',
                  sm: '24px',
                  md: '32px'
                },
                lineHeight: {
                  xs: '20px',
                  sm: '28px',
                  md: '38px'
                }
              }}
            >
              Humberto Caballero
            </Typography>
            <Typography
              className={styles.subtitle}
              sx={{
                marginBottom: '80px',
                letterSpacing: {
                  xs: '10px',
                  sm: '12px'
                },
                fontSize: {
                  xs: '10px',
                  sm: '12px'
                }
              }}
            >
              Ginecólogo
            </Typography>

            <Typography
              className={styles.title}
              sx={{
                fontSize: {
                  xs: '16px',
                  sm: '24px',
                  md: '32px'
                },
                lineHeight: {
                  xs: '20px',
                  sm: '28px',
                  md: '38px'
                }
              }}
            >
              Presentación
            </Typography>
          </Box>
        </Box>
      </HomeLayout>
    </Box>
  )
}

export default Presentation
