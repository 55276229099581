import Home from 'pages/Home'
import Media from 'pages/Media'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

const Router = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/media" element={<Media />} />
      </Routes>
    </BrowserRouter>
  )
}

export default Router
