import { CarouselItemProps } from './infinitecarousel.interface'
const image1 = require('assets/images/equipment/image-1.png')
const image2 = require('assets/images/equipment/image-2.png')
const image3 = require('assets/images/equipment/image-3.png')
const image4 = require('assets/images/equipment/image-4.png')
const image5 = require('assets/images/equipment/image-5.png')
const image6 = require('assets/images/equipment/image-6.png')

export const carouselItems: CarouselItemProps[] = [
  {
    id: 0,
    image: image1,
    title: 'Microscopio Karl Zeiss',
    specialtie: [
      {
        image: image1,
        description: 'De contraste de fases para estudio citológico y microbiológico inmediato en fresco'
      }
    ]
  },
  {
    id: 1,
    image: image2,
    title: 'Mobiliario Ginecológico Midmark',
    specialtie: [
      {
        image: image2,
        description: 'Ergonomía para confort y bioseguridad del paciente.'
      }
    ]
  },
  {
    id: 2,
    image: image3,
    title: 'Colposcopio Karl Zeiss',
    specialtie: [
      {
        image: image3,
        description: 'Aumento hasta 20 veces del tracto genital inferior'
      }
    ]
  },
  {
    id: 3,
    image: image4,
    title: 'Torre de Histeroscopia Karl Storz',
    specialtie: [
      {
        image: image4,
        description: 'Imagen asistida por vídeo digital del interior del útero y las trompas de Falopio'
      }
    ]
  },
  {
    id: 4,
    image: image5,
    title: 'Ultrasonido GE Voluson 730 Expert 3D-4D',
    specialtie: [
      {
        image: image5,
        description: 'Imágenes de alta resolución para preservar la salud femenina'
      }
    ]
  },
  {
    id: 5,
    image: image6,
    title: 'Cirugía endoscópica',
    specialtie: [
      {
        image: image6,
        description: 'Procedimientos quirúrgicos mínimamente invasivos'
      }
    ]
  },
]
