import { Box, Typography } from '@mui/material'
import HomeLayout from 'components/HomeLayout'
import styles from './equipment.module.css'
import InfiniteCarousel from './InfiniteCarousel'

const Equipment = () => {
  return (
    <Box className={styles.wrapper}>
      <HomeLayout
        className={styles.container}
        style={{
          padding: {
            xs: '50px 0px',
            sm: '30px 0px',
            md: '60px 0px'
          },
          alignItems: {
            xs: 'center',
            md: 'flex-start'
          }
        }}
      >
        <Box
          sx={{
            paddingLeft: {
              xs: '0px',
              md: '90px',
              lg: '120px'
            }
          }}
        >
          <Typography
            className={styles.title}
            sx={{
              fontSize: {
                xs: '32px',
                lg: '40px'
              },
              lineHeight: {
                xs: '38px',
                lg: '46px'
              },
              textAlign: {
                xs: 'center',
                md: 'left'
              }
            }}
          >
            Equipos Médicos
          </Typography>
          {/* <Typography
            className={styles.text}
            sx={{
              fontSize: {
                xs: '12px',
                lg: '16px'
              },
              lineHeight: {
                xs: '24px',
                lg: '24px'
              },
              textAlign: {
                xs: 'center',
                md: 'left'
              }
            }}
          >
            Lorem ipsum dolor sit amet, consectetur
          </Typography> */}
        </Box>
        <InfiniteCarousel />
        <Box className={styles['gradient-box']}></Box>
      </HomeLayout>
    </Box>
  )
}

export default Equipment
