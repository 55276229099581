import { Box } from "@mui/material"
import Navbar from 'components/Navbar'
import styles from './media.module.css'
import HomeLayout from 'components/HomeLayout'
import Footer from 'components/Footer'
import Presentation from "./Presentation"
import DisplayBox from "./DisplayBox"

const Media = () => {
  return (
    <Box className={styles.container}>
      <HomeLayout
        className={styles.navbar}
        style={{
          padding: {
            xs: '10px 30px 10px 30px',
            sm: '20px 30px 10px 30px',
            md: '20px 60px 10px 60px'
          }
        }}
      >
        <Navbar />
      </HomeLayout>
      <Presentation />
      <DisplayBox />
      <HomeLayout
        className={styles.footer}
        style={{
          padding: {
            xs: '70px 30px 40px 30px',
            sm: '70px 70px 40px 70px',
            md: '100px 100px 50px 100px'
          }
        }}
      >
        <Footer />
      </HomeLayout>
    </Box>
  )
}

export default Media