import { Box } from '@mui/material'
import headerLogo from 'assets/logos/header.svg'
import styles from './footer.module.css'
import { footerBoxes, footerSocialMedia } from './footer.array'
import FooterBox from './FooterBox'
import SocialBox from './SocialBox'
import specialtiesLogo from 'assets/logos/specialties-logo.svg'

const Footer = () => {
  return (
    <Box
      className={styles.container}
      sx={{
        justifyContent: {
          xs: 'center',
          // lg: 'space-between'
        }
      }}
    >
      <Box
        component={'figure'}
        className={styles['figure-logo']}
        sx={{
          width: {
            xs: '270px',
            md: '270px'
          },
          height: {
            xs: '270px',
            md: '270px'
          },
          top: {
            xs: '-75px',
            sm: '-75px',
            md: '-109px'
          },
          left: {
            xs: '70%',
            sm: '89%',
            md: '94%',
            lg: '94%',
            xl: '1260px'
          }
        }}
      >
        <img src={specialtiesLogo} className={styles.image} alt="Logo" />
      </Box>
      <Box component={'figure'} className={styles.figure}>
        <img className={styles.image} src={headerLogo} alt={'Logo'} />
      </Box>
      {/* <Box className={styles['box-container']}>
        {footerBoxes.boxes.map((box, index) => (
          <FooterBox
            key={`footer-${index}`}
            title={box.title}
            links={box.links}
          />
        ))}
        <SocialBox media={footerSocialMedia.media} />
      </Box> */}
    </Box>
  )
}

export default Footer
