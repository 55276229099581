import { Box, Typography } from '@mui/material'
import styles from './contact.module.css'
import HomeLayout from 'components/HomeLayout'
import { contactArray } from './contact.array'

const Contact = () => {
  return (
    <Box className={styles.wrapper} id={'contact'}>
      <HomeLayout
        style={{
          padding: {
            xs: '120px 30px',
            sm: '120px 30px',
            md: '140px 60px'
          }
        }}
      >
        <Box className={styles['text-container']}>
          <Typography
            className={styles.title}
            sx={{
              fontSize: {
                xs: '32px',
                md: '40px'
              },
              lineHeight: {
                xs: '38px',
                md: '46px'
              },
              marginBottom: {
                xs: '21px',
                md: '26px'
              }
            }}
          >
            Contacto
          </Typography>
          {/* <Typography
            className={styles.text}
            sx={{
              fontSize: {
                xs: '14px',
                md: '16px'
              },
              lineHeight: {
                xs: '18px',
                md: '20px'
              }
            }}
          >
            Lorem ipsum dolor sit amet, consectetur{' '}
          </Typography> */}
        </Box>
        <Box
          className={styles['contact-box']}
          sx={{
            padding: {
              xs: '60px 30px',
              md: '80px 40px'
            },
            flexDirection: {
              xs: 'column',
              md: 'row'
            }
          }}
        >
          {contactArray.map((contact, index) => {
            return (
              <Box
                className={
                  index !== contactArray.length
                    ? styles['mini-box-container']
                    : styles['mini-box-container-last']
                }
                key={contact.title}
                sx={{
                  minWidth: {
                    xs: '265px',
                    md: '308px',
                    xl: 'auto'
                  },
                  marginRight: {
                    xs: '0',
                    md: index !== contactArray.length
                      ? '60px'
                      : '0'
                  }
                }}
              >
                <Box
                  component={'figure'}
                  className={styles['mini-box-figure']}
                  sx={{
                    width: {
                      xs: '50px',
                      md: '60px',
                      xl: '75px'
                    },
                    height: {
                      xs: '50px',
                      md: '60px',
                      xl: '75px'
                    }
                  }}
                >
                  <img
                    src={contact.image}
                    alt={contact.title}
                    className={styles['mini-box-image']}
                  />
                </Box>
                <Box className={styles['mini-box']}>
                  <Typography
                    className={styles['mini-box-title']}
                    sx={{
                      fontSize: {
                        xs: '18px',
                        md: '21px'
                      },
                      lineHeight: {
                        xs: '20px',
                        md: '23px'
                      },
                      marginBottom: '10px'
                    }}
                  >
                    {contact.title}
                  </Typography>
                  <Typography
                    className={styles['mini-box-text']}
                    sx={{
                      fontSize: {
                        xs: '18px',
                        md: '21px'
                      },
                      lineHeight: {
                        xs: '20px',
                        md: '23px'
                      }
                    }}
                  >
                    {contact.value}
                  </Typography>
                </Box>
              </Box>
            )
          })}
          <Box className={styles['gradient-box']}></Box>
        </Box>
      </HomeLayout>
    </Box>
  )
}

export default Contact
