import { Box, Typography } from '@mui/material'
import HomeLayout from 'components/HomeLayout'
import specialtiesLogo from 'assets/logos/specialties-logo.svg'
import styles from './specialties.module.css'
import SpecialtiesCarrousel from './SpecialtiesCarrousel'

const Specialties = () => {
  return (
    <Box className={styles.wrapper}>
      <Box
        component={'figure'}
        className={styles['figure-logo']}
        sx={{
          width: {
            xs: '869px',
            md: '869px'
          },
          height: {
            xs: '775px',
            md: '775px'
          },
          top: {
            xs: '10px',
            sm: '60px',
            md: '60px'
          },
          left: {
            xs: '-600px',
            sm: '-400px',
            md: '-398px',
            lg: '-45px'
          }
        }}
      >
        <img src={specialtiesLogo} className={styles.image} alt="Logo" />
      </Box>
      <HomeLayout
        className={styles.container}
        style={{
          padding: {
            xs: '120px 30px',
            sm: '120px 30px',
            md: '140px 60px'
          }
        }}
      >
        <Box className={styles['text-container']}>
          <Typography
            className={styles.title}
            sx={{
              fontSize: {
                xs: '32px',
                md: '40px'
              },
              lineHeight: {
                xs: '38px',
                md: '46px'
              },
              marginBottom: {
                xs: '12px',
                md: '26px'
              }
            }}
          >
            Especialidades
          </Typography>
          {/* <Typography
            className={styles.text}
            sx={{
              fontSize: {
                xs: '14px',
                md: '16px'
              },
              lineHeight: {
                xs: '18px',
                md: '20px'
              }
            }}
          >
            Lorem ipsum dolor sit amet, consectetur{' '}
          </Typography> */}
        </Box>
        <SpecialtiesCarrousel/>
      </HomeLayout>
    </Box>
  )
}

export default Specialties
