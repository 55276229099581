import { Box, Typography } from '@mui/material'
import styles from './displaybox.module.css'
import HomeLayout from 'components/HomeLayout'
import { books, media } from './displaybox.array'
import { Display, Media } from './displaybox.interface'
import { useState } from 'react'
import DisplayButton from './DisplayButton'
import DisplayBooks from './DisplayBooks'
import DisplayMedia from './DisplayMedia'

const DisplayBox = () => {
  const [display, setDisplay] = useState<Display>('Libros')
  const [loading, setLoading] = useState<boolean>(false)
  const booksArray: Media[] = books
  const mediaArray: Media[] = media
  return (
    <Box className={styles.wrapper}>
      <HomeLayout
        className={styles.container}
        style={{
          padding: {
            xs: '0px 30px 20px 30px',
            md: '0px 60px 40px 60px'
          }
        }}
      >
        <Box className={styles['display-container']}>
          <DisplayButton
            value={display}
            text="Libros"
            onClick={() => setDisplay('Libros')}
          />
          <DisplayButton
            value={display}
            text="Media"
            onClick={() => setDisplay('Media')}
          />
        </Box>
        <Box className={styles['media-container']}>
          {display === 'Libros' 
            ? (
              <Box className={styles['books-container']}>
                {booksArray.map(item => (
                  <DisplayBooks
                    key={item.title}
                    title={item.title}
                    url={item.url}
                    image={item.image}
                    description={item.description}
                    loading={loading}
                    loadingFunction={setLoading}
                  />
                ))}
              </Box>
            )
            : (media.length === 0) ? (
              <Typography
                className={styles['no-text']}
                sx={{
                  fontSize: {
                    xs: '16px !important',
                    lg: '24px !important'
                  },
                  letterSpacing: {
                    xs: '5px !important',
                    sm: '9px !important',
                    md: '16px !important',
                    lg: '20px !important'
                  }
                }}
              >
                Sin videos disponibles
              </Typography>
            ) : (
              <Box className={styles['books-container']}>
                {mediaArray.map(item => (
                  <DisplayMedia
                    key={item.title}
                    title={item.title}
                    url={item.url}
                    image={item.image}
                    description={item.description}
                  />
                ))}
              </Box>
            )
          }
        </Box>
      </HomeLayout>
    </Box>
  )
}

export default DisplayBox
