import { Box } from '@mui/material'
import { HomeLayoutProps } from './homelayout.interface'

const HomeLayout = ({ children, className, style }: HomeLayoutProps) => {
  return (
    <Box
      className={className}
      sx={{
        padding: {
          xs: '70px 30px',
          sm: '70px 70px',
          md: '100px 100px'
        },
        maxWidth: {
          xs: 'none',
          xl: '1500px'
        },
        width: '100%',
        ...style
      }}
    >
      {children}
    </Box>
  )
}

export default HomeLayout
