import { Box, Button, Typography } from '@mui/material'
import styles from './displaymedia.module.css'
import { Media } from '../displaybox.interface'
import playIcon from 'assets/icons/play.svg'

const DisplayMedia = ({ title, url, image, description }: Media) => {
  return (
    <Box className={styles.container}>
      <Box className={styles['mini-container']}>
        <Box className={styles.figure}>
          <img src={playIcon} alt="Play" className={styles.image} />
        </Box>
      </Box>
      <Box className={styles['text-container']}>
        <Typography className={styles.title}>{title}</Typography>
        <Typography className={styles.description}>{description}</Typography>
      </Box>
    </Box>
  )
}

export default DisplayMedia
