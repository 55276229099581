import { useNavigate } from 'react-router-dom'
import { NavbarMenu } from '../navbar.interface'
import { Box, Typography } from '@mui/material'
import styles from './navbarbox.module.css'

const NavbarBox = ({ title, link }: NavbarMenu) => {
  const navigate = useNavigate()
  const location = window.location.pathname
  return (
    <Box
      className={styles.container}
      onClick={() => navigate(link)}
      sx={{
        padding: {
          xs: '0px 5px',
          lg: '0px 18px'
        },
      }}
    >
      <Typography
        className={styles.text}
        sx={{
          fontWeight: {
            xs: '400 !important',
            lg: '500 !important'
          },
          fontSize: {
            xs: '12px',
            md: '14px',
            lg: '16px'
          },
          opacity: location === link ? '1 !important' : '0.7 !important'
        }}
      >
        {title}
      </Typography>
    </Box>
  )
}

export default NavbarBox
