import { Box } from '@mui/material'
import Introduction from './Introduction'
import Description from './Description'
import Contact from './Contact'
import Specialties from './Specialties'
import Navbar from 'components/Navbar'
import styles from './home.module.css'
import HomeLayout from 'components/HomeLayout'
import Footer from 'components/Footer'
import Equipment from './Equipment'

function Home() {
  return (
    <Box className={styles.container}>
      <HomeLayout
        className={styles.navbar}
        style={{
          padding: {
            xs: '10px 30px 10px 30px',
            sm: '20px 30px 10px 30px',
            md: '20px 60px 10px 60px'
          }
        }}
      >
        <Navbar />
      </HomeLayout>
      <Introduction />
      <Description />
      <Specialties />
      <Equipment />
      <Contact />
      <HomeLayout
        className={styles.footer}
        style={{
          // padding: {
          //   xs: '20px 30px 40px 30px',
          //   sm: '20px 30px 40px 30px',
          //   md: '20px 30px 40px 30px'
          // }
          padding: {
            xs: '70px 30px 40px 30px',
            sm: '70px 70px 40px 70px',
            md: '70px 100px 50px 100px'
          }
        }}
      >
        <Footer />
      </HomeLayout>
    </Box>
  )
}

export default Home
