import { Box, Typography } from '@mui/material'
import pinkLogo from 'assets/logos/pink-logo.svg'
import styles from './description.module.css'
import HomeLayout from 'components/HomeLayout'

const Description = () => {
  const descriptionImg = require('assets/images/description-image.png')
  return (
    <Box className={styles.wrapper}>
      <HomeLayout
        className={styles.container}
        style={{
          flexDirection: {
            xs: 'column',
            md: 'row'
          }
        }}
      >
        <Box
          sx={{
            maxWidth: {
              xs: '100%',
              sm: '440px',
              md: '350px',
              lg: '520px'
            }
          }}
        >
          <Typography
            className={styles.title}
            sx={{
              fontSize: {
                xs: '32px',
                lg: '40px'
              },
              lineHeight: {
                xs: '38px',
                lg: '46px'
              }
            }}
          >
            Dr Humberto Caballero Lindner
          </Typography>
          <Typography
            className={styles.text}
            sx={{
              fontSize: {
                xs: '12px',
                lg: '16px'
              },
              lineHeight: {
                xs: '24px',
                lg: '30px'
              }
            }}
          >
            Humberto Javier Caballero Lindner (Caracas, 1966) es ex - alumno
            Senior del Colegio San Ignacio de Loyola. Cursó estudios de Medicina
            en la Universidad Nacional Experimental Francisco de Miranda en
            Coro, Estado Falcón. Realizó Post-grado de Ginecología y
            Reproducción Humana en el Hospital Carlos J. Bello, Cruz Roja
            Venezolana, junto al maestro Víctor Benaím Pinto. Es autor de 2
            libros de cuentos: La Danza Virtual de los Recuerdos (1996) y El
            Suspiro Indeleble (2007). En el campo científico, ha publicado:
            Atlas Iconográfico de Colposcopia (2003), Atlas de Ecografía Mamaria
            Digital (2005), VPH: Virus Papiloma Humano en la Práctica Médica
            (2006), El Ovario Humano (2008) e Histeroscopia en la Práctica
            Clínica (2010). En el año 2017 publicó el atlas El Ávila en su
            asombro.
          </Typography>
          <Box component={'figure'} className={styles['figure-logo']}>
            <img
              src={pinkLogo}
              alt="Placeholder"
              className={styles['image-logo']}
            />
          </Box>
        </Box>
        <Box
          component={'figure'}
          sx={{
            width: {
              xs: '100%',
              sm: '440px',
              md: '330px',
              lg: '430px'
            },
            marginTop: {
              xs: '40px',
              md: '0px'
            }
          }}
        >
          <img src={descriptionImg} alt="Pink Logo" className={styles.image} />
        </Box>
        <Box className={styles['gradient-box']}></Box>
      </HomeLayout>
    </Box>
  )
}

export default Description
