import { ThemeProvider } from '@emotion/react'
import { createTheme } from '@mui/material'
import { ReactElement } from 'react'

interface ThemeProps {
  children: ReactElement | ReactElement[]
}

const Theme = ({ children }: ThemeProps): JSX.Element => {
  const theme = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            borderRadius: '8px',
            padding: '12px 24px',
            backgroundColor: 'var(--dark-pink)',
            color: 'var(--white)',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            transition: 'all .5s ease-in-out',
            '&:hover': {
              color: 'var(--dark-pink)',
              backgroundColor: 'var(--white)',
              transition: 'all .5s ease-in-out'
            }
          }
        }
      }
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 500,
        md: 850,
        lg: 1200,
        xl: 1500
      }
    },
    typography: {
      fontFamily: 'KyviType Sans Regular, sans serif, sans-serif'
    }
  })
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export default Theme
