import { Box, Typography } from '@mui/material'
import { DisplayButtonProps } from '../displaybox.interface'
import styles from './displaybutton.module.css'

const DisplayButton = ({text, onClick, value}: DisplayButtonProps) => {
  return (
    <Box
      className={styles['border-container']}
      sx={{
        backgroundImage: text === value ? 'linear-gradient(90deg, #FA91B4 0%, #A3D4F5 100%)' : 'none',
      }}
    >
      <Box
          onClick={onClick}
          className={styles.container}
      >
        <Typography
          className={styles.text}
          sx={{
              fontSize: {
                  xs: '12px',
                  sm: '16px',
                  md: '20px'
              },
          }}
        >
          {text}
        </Typography>
      </Box>
    </Box>
  )
}

export default DisplayButton
