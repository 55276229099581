import { Box, Typography } from '@mui/material'
import styles from './specialtiesbox.module.css'
import { SpecialtiesProps } from '../specialties.interface'

const SpecialtiesBox = ({ title, text, img }: SpecialtiesProps) => {
  return (
    <Box
      className={styles.container}
      sx={{
        padding: {
          xs: '30px 10px',
          sm: '50px 50px',
          // minWidth: '200px'
        },
      }}
    >
      <Box component={'figure'} className={styles.figure}>
        <img className={styles.image} src={img} alt={'Especialidad'} />
      </Box>
      <Typography
        className={styles.title}
        sx={{
          fontSize: {
            xs: '16px',
            md: '24px'
          },
          lineHeight: {
            xs: '24px',
            md: '28px'
          },
          marginTop: {
            xs: '17px',
            md: '27px'
          },
          marginBottom: {
            xs: '12px',
            md: '17px'
          }
        }}
      >
        {title}
      </Typography>
      <Typography
        className={styles.text}
        sx={{
          fontSize: {
            xs: '11px',
            md: '16px'
          },
          lineHeight: {
            xs: '14px',
            md: '18px'
          }
        }}
      >
        {text}
      </Typography>
    </Box>
  )
}

export default SpecialtiesBox
