import { Box, Typography } from '@mui/material'
import introLogo from 'assets/logos/intro-logo.svg'
import styles from './introduction.module.css'
import HomeLayout from 'components/HomeLayout'

function Introduction() {
  const introImg = require('assets/images/intro-image.png')
  return (
    <Box className={styles.wrapper}>
      <Box
        component={'figure'}
        className={styles.figure}
        sx={{
          width: {
            xs: '700px',
            md: '700px'
          },
          top: {
            xs: '-100px',
            md: '-77px'
          },
          right: {
            xs: '-200px',
            md: '-200px'
          }
        }}
      >
        <img src={introLogo} className={styles.image} alt="Logo" />
      </Box>
      <HomeLayout
        className={styles.container}
        style={{
          padding: {
            xs: '40px 30px',
            sm: '40px 30px',
            md: '40px 60px 80px 60px'
          }
        }}
      >
        <Box
          className={styles.content}
          sx={{
            flexDirection: {
              xs: 'column-reverse',
              sm: 'row'
            },
            alignItems: {
              xs: 'flex-start',
              sm: 'center'
            }
          }}
        >
          <Box
            sx={{
              maxWidth: {
                xs: 'none',
                sm: '480px',
                lg: '500px',
                xl: 'none'
              },
              borderRadius: '10px'
            }}
          >
            <img className={styles.image} src={introImg} alt={'Women'} />
          </Box>
          <Box
            sx={{
              marginBottom: {
                xs: '30px',
                sm: '0px'
              },
              marginLeft: {
                xs: '0px',
                sm: '8%',
                md: '8%'
              },
              width: {
                xs: 'auto',
                sm: '580px'
              }
            }}
          >
            <Typography
              className={styles.title}
              sx={{
                fontSize: {
                  xs: '32px',
                  md: '42px',
                  lg: '55px'
                },
                lineHeight: {
                  xs: '40px',
                  md: '46px',
                  lg: '62px'
                },
                // marginBottom: {
                //   xs: '20px',
                //   sm: '28px',
                //   md: '10px'
                // }
              }}
            >
              Doctor
            </Typography>
            <Typography
              className={styles.title}
              sx={{
                fontSize: {
                  xs: '32px',
                  md: '42px',
                  lg: '55px'
                },
                lineHeight: {
                  xs: '40px',
                  md: '46px',
                  lg: '62px'
                },
                marginBottom: {
                  xs: '20px',
                  sm: '28px',
                  md: '45px'
                }
              }}
            >
              Humberto Caballero Lindner
            </Typography>
            <Typography
              className={styles.text}
              sx={{
                fontSize: {
                  xs: '15px',
                  sm: '16px',
                  md: '25px',
                  lg: '35px'
                },
                lineHeight: {
                  xs: '18px',
                  sm: '19px',
                  md: '28px',
                  lg: '38px'
                },
                width: {
                  xs: '150px',
                  sm: '100%'
                }
              }}
            >
              GINECÓLOGO
            </Typography>
          </Box>
        </Box>
      </HomeLayout>
    </Box>
  )
}

export default Introduction
