import { Box } from '@mui/material'
import arrowIcon from 'assets/icons/arrow.svg'
import styles from './specialtiesarrow.module.css'
import React from 'react'

const SpecialtiesArrow = (props: any) => {
  const { prev, onClick, classname, style } = props
  return (
    <Box onClick={onClick} className={styles['arrow-container']}>
      <img
        className={classname}
        alt="Arrow"
        src={arrowIcon}
        style={{ ...style, rotate: prev ? '0deg' : '180deg', padding: '0 20px 0 10px' }}
      />
    </Box>
  )
}

export default SpecialtiesArrow
