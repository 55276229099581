import { Media } from "./displaybox.interface"
const ovario = require('assets/images/books/ovario.png')
const histeroscopia = require('assets/images/books/histeroscopia.png')
const vph = require('assets/images/books/vph.png')
const avila = require('assets/images/books/avila.png')
const danza = require('assets/images/books/danza.png')
const suspiro = require('assets/images/books/suspiro.png')

const books: Media[] = [
    {
        title: "Atlas del Ovario Humano",
        url: "https://storage.googleapis.com/pulpitech-drcaballero/Atlas%20Ovarios%20-%20Dr%20Humberto%20Caballero%20(1).pdf",
        image: ovario,
        description: "Iconografía en Ultrasonido transvaginal del Ovario y su aplicación"
    },
    {
        title: "VPH en la Práctica Clínica",
        url: "https://storage.googleapis.com/pulpitech-drcaballero/VPH%20-%20Humberto%20Caballero%20(1).pdf",
        image: vph,
        description: "Atlas colposcópico del Virus Papiloma Humano en el tracto genital inferior"
    },
    {
        title: "Histeroscopia en la Práctica Clínica",
        url: "https://storage.googleapis.com/pulpitech-drcaballero/Histeroscopia%20-%20Humberto%20Caballero.pdf",
        image: histeroscopia,
        description: "Atlas endoscópico del Útero Humano normal y patológico"
    },
    {
        title: "El Ávila en su asombro",
        url: "https://storage.googleapis.com/pulpitech-drcaballero/El%20Avila%20En%20Su%20Asombro.pdf",
        image: avila,
        description: "Atlas del Ávila, los caminos y su historia."
    },
    {
        title: "La Danza virtual de los recuerdos",
        url: "https://storage.googleapis.com/pulpitech-drcaballero/DANZA%20VIRTUAL%201996-comprimido.pdf",
        image: danza,
        description: "Cuentos"
    },
    {
        title: "El Suspiro indeleble",
        url: "https://storage.googleapis.com/pulpitech-drcaballero/el%20suspiro%20indeleble.pdf",
        image: suspiro,
        description: "Prosa poética"
    },
]
const media: Media[] = [
    // {
    //     title: "",
    //     url: "",
    //     image: "",
    //     description: ""
    // },
    // {
    //     title: "",
    //     url: "",
    //     image: "",
    //     description: ""
    // },
    // {
    //     title: "",
    //     url: "",
    //     image: "",
    //     description: ""
    // },
]

export { books, media }